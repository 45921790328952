
import { Component, Prop, Vue } from "vue-property-decorator";

import { jasperService } from "../../utils/http";
import CustomerBase from '@/common/customer.base'
import AllFirmWithAllReports from './views/AllFirmWithAllReports.vue'
import ByFirm from './views/ByFirm.vue'
import ByReport from './views/ByReport.vue'
import DateRangePicker from 'vue2-daterange-picker'
import dateTimeUtility from "@/utils/datetime.utils";
import Grid from './views/Grid.vue'
import Totals from "./views/Totals.vue";
@Component({
  components: {
    Totals,
    DateRangePicker,
    Grid,
    AllFirmWithAllReports,
    ByFirm,
    ByReport
  }
})
export default class JasperReportRuns extends CustomerBase {
  analytics: { filter: any, data: any[] } = null;
  dateRange: { startDate:Date, endDate:Date }={startDate:new Date(dateTimeUtility.monthStart()),endDate:new Date(dateTimeUtility.monthEnd())}
  totals:any=null;
  created() {
   this.loadData();
  }
  datesUpdated(dateRange){
    this.loadData();
  }
  loadData(){
    this.isBusy(true)
    this.analytics=null;
    // console.log('series',this.generateDayWiseTimeSeries(0,18))
    jasperService.getAnalytics(dateTimeUtility.toDate(this.dateRange.startDate),dateTimeUtility.toDate(this.dateRange.endDate)).then(result => {
      this.isBusy(false)
      this.analytics=result;
      this.totals=this.calculateTotals(this.analytics.data);

  
    }).catch(err => this.isBusy(false))
  }
  calculateTotals(data) {
  const regionCounts = data.reduce((target, entry) => {
  

    const existingRegion = target.find((r) => r.region === entry.region);

    if (existingRegion) {
      existingRegion.total++;
      if (entry.isSecondaryDb) {
        existingRegion.secondaryDbCount++;
      } else {
        existingRegion.primaryDbCount++;
      }
    } else {
      const newRegion = {
        region: entry.region,
        secondaryDbCount: entry.isSecondaryDb ? 1 : 0,
        primaryDbCount: entry.isSecondaryDb ? 0 : 1,
        total:1
      };
      target.push(newRegion);
    }

    return target;
  }, []);



const grandTotal = regionCounts.reduce(
    (total, region) => {
     
      total += region.secondaryDbCount + region.primaryDbCount;

      return total;
    },
    0 
  );
return {
  total: grandTotal,
  regions:regionCounts
}
  

}
}
