
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";
import CommonRightIds from "../../common/rights";
import {
  CustomerViewModel,
  DBDeletionStatus,
  JobStatus,
  JobType,
  DatabaseStatus,
  Backup,
  JobViewModel
} from "../../common/models";
import LoadCustomer from "../common/LoadCustomer.vue";
import FirmDetails from "./FirmDetails.vue";
import { dbService, syncService,subscriptionService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import BatchQuery from './BatchQuery.vue';
import EnablePermissions from './EnablePermissions.vue';
import { workflowService } from "../../utils/http";
import { messageService } from "@/utils/message.service";


@Component({
  components: {
    VueJsonPretty,
    LoadCustomer,
    EnablePermissions,
    FirmDetails,
    BatchQuery
  }
})
export default class DbOperations extends CustomerBase {
  tenantFilter={
   showActiveCustomers:true,
   hasActiveSubscription:null,
   isPaid:null,
   isDemo:null,
   lastAccessOlderThan:null,
   subscriptionName:'',
   hasNewWorkflow:null,
   filterTenants(tenants){
    return tenants.filter(tenant => {
      if(this.subscriptionName && tenant.subscription != this.subscriptionName){
        return false
      }
      if(this.showActiveCustomers && tenant.dbDeletionStatus != DBDeletionStatus.Active){
      return false;
      }
      else if(!this.showActiveCustomers && tenant.dbDeletionStatus==DBDeletionStatus.Active){
        return false;
      }
      if (this.hasActiveSubscription !== null ) {
       return tenant.hasActiveSubscription == this.hasActiveSubscription
      }
      if (this.hasNewWorkflow !== null && tenant.hasNewWorkflow !== this.hasNewWorkflow) {
        return false;
      }
      if (this.isPaid !== null && tenant.isPaid !== this.isPaid) {
        return false;
      }
      if (this.isDemo !== null && tenant.isDemo !== this.isDemo) {
        return false;
      }
      if (this.lastAccessOlderThan !== null) {
        const cutoffDate = new Date();
        cutoffDate.setMonth(cutoffDate.getMonth() - this.lastAccessOlderThan);
        const lastActivityDate = tenant.lastActivity ? new Date(tenant.lastActivity) : new Date();
        if (lastActivityDate >= cutoffDate) {
          return false;
        }
      }
      return true;
    });
   }
  };
  showAdvancedOptions=false;
  showStats=false;
  filter=''
  selectedFirm:any=null;
  filterOn= ['label']
  showOutput = false;
  outputData = {};
  selectVersion: string = "";
  backupUploadFile: null;
  updateInterval: any = null;
  totalRows= 1;
        currentPage= 1;
        perPage= 100;

  constructor() {
    super();
  }


  get versionStats(): any {
    var grouped = this.groupBy(this.visibleCustomers, customer => customer.dbVersion);
    return Array.from(grouped.keys()).map(z => ({
      version: z,
      count: grouped.get(z).length
    }));
  }
  get jasperReportStats(): any {
    var grouped = this.groupBy(this.visibleCustomers, customer => customer.jasperEndpointStatusName);
    return Array.from(grouped.keys()).map(z => ({
      endpoint: z,
      count: grouped.get(z).length
    }));
  }
  get replicationStats(): any {
    var grouped = this.groupBy(this.visibleCustomers, customer => customer.replicationStatusName);
    return Array.from(grouped.keys()).map(z => ({
      status: z,
      count: grouped.get(z).length
    }));
  }
  get activePaidSubscriptionStats(): any {
    var grouped = this.groupBy(this.visibleCustomers.filter(z=>z.hasActiveSubscription && z.isPaid), customer => customer.subscription);
    return Array.from(grouped.keys()).map(z => ({
      subscription: z,
      count: grouped.get(z).length,
      seats: grouped.get(z).reduce((total, firm) => {
    return total + firm.seats;
}, 0)
    }));
  }
  get totals(): any {
    return {
      totalFirms:this.visibleCustomers.length,
      activePayingCustomers:this.visibleCustomers.filter(z=>z.hasActiveSubscription && z.isPaid).length,
      inActivePayingCustomers: this.visibleCustomers.filter(z=>z.isPaid && !z.hasActiveSubscription).length,
      unpaidCustomers: this.visibleCustomers.filter(z=>!z.isPaid).length
    }
    
  }
  get nonPaidStats(): any {
    console.log('nonpaid firms',this.visibleCustomers.filter(z=>!z.isPaid))
    var grouped = this.groupBy(this.visibleCustomers.filter(z=>!z.isPaid), customer => customer.isDemo);
    return Array.from(grouped.keys()).map(z => ({
      type: z?'Demo':'NonBillable',
      count: grouped.get(z).length,
      seats: grouped.get(z).reduce((total, firm) => {
    return total + firm.seats;
}, 0)
    }));
  }
  get lastUsedStats(): any {
    let today = new Date();
    let lastWeeksDate = new Date();
    lastWeeksDate.setDate(today.getDate() - 7);
    let lastTwoWeeksDate = new Date();
    lastTwoWeeksDate.setDate(today.getDate() - 15);
    let lastMonthDate = new Date();
    lastMonthDate.setDate(today.getDate() - 30);
    let last3MonthDate = new Date();
    last3MonthDate.setDate(today.getDate() - 90);
    let last6MonthDate = new Date();
    last6MonthDate.setDate(today.getDate() - 120);

    let result = [];

    result.push({
      lastUsed: "Last Week",
      count: this.visibleCustomers.filter(
        z =>
          z.lastActivity &&
          new Date(z.lastActivity).getTime() >= lastWeeksDate.getTime()
      ).length
    });

    result.push({
      lastUsed: "Last Two Weeks",
      count: this.visibleCustomers.filter(
        z =>
          z.lastActivity &&
          new Date(z.lastActivity).getTime() >= lastTwoWeeksDate.getTime() &&
          new Date(z.lastActivity).getTime() < lastWeeksDate.getTime()
      ).length
    });

    result.push({
      lastUsed: "Last  Month",
      count: this.visibleCustomers.filter(
        z =>
          z.lastActivity &&
          new Date(z.lastActivity).getTime() >= lastMonthDate.getTime() &&
          new Date(z.lastActivity).getTime() < lastTwoWeeksDate.getTime()
      ).length
    });

    result.push({
      lastUsed: "Last Three Months",
      count: this.visibleCustomers.filter(
        z =>
          z.lastActivity &&
          new Date(z.lastActivity).getTime() >= last3MonthDate.getTime() &&
          new Date(z.lastActivity).getTime() < lastMonthDate.getTime()
      ).length
    });
    result.push({
      lastUsed: "Last Six Months",
      count: this.visibleCustomers.filter(
        z =>
          z.lastActivity &&
          new Date(z.lastActivity).getTime() >= last6MonthDate.getTime() &&
          new Date(z.lastActivity).getTime() < last3MonthDate.getTime()
      ).length
    });
    result.push({
      lastUsed: "Before Six Months",
      count: this.visibleCustomers.filter(
        z =>
          !z.lastActivity ||
          new Date(z.lastActivity).getTime() < last6MonthDate.getTime()
      ).length
    });

    return result;
  }
  allFields=[
    { key: "checked", sortable: false,label:'' },
    { key: "show_details", sortable: false,label:'Details' },
    { key: "label", sortable: true,label:'Url' },
    { key: "dbDeletionStatusName", sortable: true,label:'Firm Status',showOnlyForDeletedFirms:true },
     { key: "email", sortable: true },
    { key: "hasActiveSubscription", sortable: true,label:'Has Active Subscription' },
    { key: "isPaid", label:'Paying Customer', sortable: true },
    { key: "isDemo",label:'Demo', sortable: true },
    { key: "subscription", sortable: true,label:'Sub' },
    { key: "seats", sortable: true,label:'Seats' },
    { key: "createdDate", sortable: true,label:'C.Date' }, 
    { key: "lastActivity", sortable: true,label:'L.Activity' },
    { key: "dbVersion", sortable: true,label:'DB Ver' },
    { key: "hasNewWorkflow", sortable: true,label:'WorkflowV2' },
    { key: "numberOfLegacyDuedates", sortable: true,label:'Legacy Master Entries' },
    
    {key:"subscrptionLockName", sortable:true,label:'S.Lock'},
    {key:"replicationStatusName", sortable:true,label:'Replication Status'},
    {key:"jasperEndpointStatusName", sortable:true,label:'Jasper Server DB'},
    // {key:"metadata", sortable:true,label:'Meta'},
  ]
  get fields(){
    if(this.tenantFilter.showActiveCustomers) {
      return this.allFields.filter(z=>!z.showOnlyForDeletedFirms)
    }  
    else return this.allFields;
  }

  public customers: CustomerViewModel[] = [];

  get visibleCustomers(): CustomerViewModel[]{

    return this.tenantFilter.filterTenants(this.customers)
  //  if(!this.showDeletedOrDeleting) {f
  //   return this.customers.filter(z=>z.dbDeletionStatus==DBDeletionStatus.Active || z.dbDeletionStatus==DBDeletionStatus.MarkedForDelete)

  //  }
  //  else{
  //   return this.customers.filter(z=>z.dbDeletionStatus==DBDeletionStatus.Deleted || z.dbDeletionStatus==DBDeletionStatus.IsDeleting)
  //  }
  }
  groupBy<T>(list: T[], keyGetter: (n: T) => any): Map<any, T[]> {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }


 
  getDbVersion(id: string) {
    this.isBusy(true);
    var customer = this.customers.find(z => z.id == id);
    dbService
      .getDbVersion(id)
      .then(result => {
        this.isBusy(false);
        customer.dbVersion = result;
      })
      .catch(err => {
        this.isBusy(false);
      });
  }
  public sync(){
   this.isBusy(true);
   syncService.syncronize().then(result=>{
     this.isBusy(false);
     this.getData();
   }).catch(err=>this.isBusy(false));
 }
 public syncDbVersion(){
   this.isBusy(true);
   syncService.syncDbVersion().then(result=>{
     this.isBusy(false);
     this.getData();
   }).catch(err=>this.isBusy(false));
 }
  deselectAll() {
    this.visibleCustomers.forEach(c => (c.checked = false));
  }
  selectAll() {
    this.deselectAll();
    this.visibleCustomers.forEach(c => (c.checked = true));
  }

  schedulePriceValidation(){
    subscriptionService.schedulePriceValidation().then(result=>{
      this.info('Started','View results on the jobs in progress')
    }).catch(err=>{
     this.info('Error','Error occured while scheduling the job')
    });
    
  }
  scheduleCustomerLinkValidation(){
    subscriptionService.validateSubscriptionToCustomerLink().then(result=>{
      this.info('Started','View results on the jobs in progress')
    }).catch(err=>{
     this.info('Error','Error occured while scheduling the job')
    });
    
  }
  linkFirmsToStripeCustomers(){
    subscriptionService.linkFirmsToStripeCustomers().then(result=>{
      this.info('Started','View results on the jobs in progress')
    }).catch(err=>{
     this.info('Error','Error occured while scheduling the job')
    });
  }
  puplateClientIDNR(){
    var firms = this.visibleCustomers.filter(z => z.checked).map(z => z.id);
    if (firms.length == 0)
      return this.info(
        "Nothing to update",
        "Please select at least one firm to continue"
      );
this.isBusy(true);
      dbService
        .populateClientIdNr(firms)
        .then(result => {
          this.isBusy(false);
           this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
  }
  convertClientsToSelfContacts(){
     var firms = this.visibleCustomers.filter(z => z.checked).map(z => z.id);
    if (firms.length == 0)
      return this.info(
        "Nothing to update",
        "Please select at least one firm to enable permissions"
      );
this.isBusy(true);
      dbService
        .convertClientsToSefContacts(firms)
        .then(result => {
          this.isBusy(false);
           this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
   
  }
    enablePermissions(permissions,onlyIfHasRight) {
      if(!permissions) return;
    var firms = this.visibleCustomers.filter(z => z.checked).map(z => z.id);
    if (firms.length == 0)
      return this.info(
        "Nothing to update",
        "Please select at least one firm to enable permissions"
      );

    this.confirm(
      "Are you really really really sure?",
      "Are you sure you want to perform this operation. This will enable these permissions for all users on the selected firms"
    ).then(result => {
      if (!result) return;
      let rights= permissions.split(',');
      this.isBusy(true);
      dbService
        .enablePermissioins(rights,onlyIfHasRight, firms)
        .then(result => {
          this.isBusy(false);
           this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
    });
  }
  executeBatchQuery(queryId) {
    var firms = this.visibleCustomers.filter(z => z.checked).map(z => z.id);
    if (firms.length == 0)
      return this.info(
        "Nothing to query",
        "Please select at least one firm to query"
      );

    this.confirm(
      "Are you really really really sure?",
      "Are you sure you want to perform this operation. This will execute a query on all firms"
    ).then(result => {
      if (!result) return;
      this.isBusy(true);
      dbService
        .executeBatchQuery(queryId, firms)
        .then(result => {
          this.isBusy(false);
           this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
    });
  }
  updateSelected() {
    this.confirm(
      "Are you really really really sure?",
      "Are you sure you want to perform this operation. This will update the firms database to the latest database stored in the db deploy folder for all selected item. "
    ).then(result => {
      if (result) {
        this.isBusy(true);
        var ids = this.visibleCustomers
          .filter(z => z.checked && z.status != DatabaseStatus.Deleted)
          .map(z => z.id);
        if (ids.length > 0) {
          dbService.updateCustomers(ids).then(result => {
            this.isBusy(false);
            result.forEach(element => {
              var customer = this.visibleCustomers.find(
                z => z.id == element.customerId
              );
              if (customer) {
                // customer.jobDetails.push(element);
              }
            });
          });
        }
      }
    });
  }
  migrateWorkflowRights(){
    var ids = this.visibleCustomers
          .filter(z => z.checked && z.status != DatabaseStatus.Deleted)
          .map(z => z.id);
        if (ids.length > 0) {
          this.isBusy(true);

          workflowService.migrateRights(ids).then(result => {
            this.isBusy(false);
            messageService.info("Job(s) started","Track the process in the jobs in progress. Jobs will run one at a time")
          });
        }
  }
  findAndFixWorkflowMigrationBadRecords(){
    var ids = this.visibleCustomers
          .filter(z => z.checked && z.status != DatabaseStatus.Deleted)
          .map(z => z.id);
        if (ids.length > 0) {
          this.isBusy(true);

          workflowService.findAndFixBadRecords(ids).then(result => {
            this.isBusy(false);
            messageService.info("Job(s) started","Track the process in the jobs in progress. Jobs will run one at a time")
          });
        }
  }
  migrateSelectedWorkflowItems() {
    this.multiConfim(
      "Are you really really really sure?",
      ["This will start the migration process for all the selected firms and the process cannot be undone. Please make sure you have selected the correct firms before continuing",
      "Just making sure once more if you are sure you want to continue",
      "Last Chance to back out"
    ]
    ).then(result => {
      if (result) {
        var ids = this.visibleCustomers
          .filter(z => z.checked && z.status != DatabaseStatus.Deleted)
          .map(z => z.id);
        if (ids.length > 0) {
          this.isBusy(true);

          workflowService.batchMigrate(ids).then(result => {
            this.isBusy(false);
            messageService.info("Job(s) started","Track the process in the jobs in progress. Jobs will run one at a time")
          });
        }
      }
    });
  }

  created() {
    this.getData();
    //@ts-ignore
    this.$eventHub.on(this.EventNames.JobsChanged, (data: JobViewModel) => {
      dbService.getCustomer(data.customerId).then(result => {
        var existing = this.customers.find(z => z.id == result.id);
        if (existing) {
          existing.label = result.label;
          existing.dbName = result.dbName;
          existing.dbVersion = result.dbVersion;
          existing.status = result.status;
          existing.statusSetDate = result.statusSetDate;
          existing.lastActivity = result.lastActivity;
          existing.runningJobs = result.runningJobs;
        } else {
          result.checked = false;
          this.customers.push(result);
        }
      });
    });
    //@ts-ignore
    this.$eventHub.on(this.EventNames.DBStatusChanged, (firmId:string) => {
      console.log('dbstatuschanged')
     this.getData();
    });
  }
  getData() {
    this.isBusy(true);
    dbService
      .getTenants()
      .then(result => {
        this.isBusy(false);
        result.forEach(item => {
          item.checked = false;
          item.deleted = false;
          if(!item.customFeatures) item.hasNewWorkflow=false;
          else item.hasNewWorkflow=item.customFeatures.split(',').includes("1007")
         
        
        });
        this.totalRows = result.length
        this.customers = result;
        console.log('result',result)
      })
      .catch(err => this.isBusy(false));
  }
}
