import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Backup } from '@/common/models';
class DbService {
  public getDbVersion(id:string): Promise<string> {
    return httpService.get(Resources.getBaseUrl() + `Databases/dbversion/` +id);
  }
 public getTenants(): Promise<CustomerViewModel[]> {
   return httpService.get(Resources.getBaseUrl() + `Tenants`);
 }
 public getTenant(firmId:string): Promise<CustomerViewModel> {
  return httpService.get(`${Resources.getBaseUrl()}Tenants/${firmId}`);
}
public updateTenantDeletionStatus(firmId:string,status:Number): Promise<void> {
  return httpService.post(`${Resources.getBaseUrl()}Tenants/${firmId}/deletionstatus/${status}`,{});
}
 public getBackups(firmId:string): Promise<Backup[]> {
  return httpService.get(Resources.getBaseUrl() + `backups/${firmId}`);
}
 
 public getCustomer(id:string): Promise<CustomerViewModel> {
  return httpService.get(Resources.getBaseUrl() + `Databases/customers/` +id);
}
 public updateCustomers(ids:string[]): Promise<JobDetailsViewModel[]> {
  return httpService.post(Resources.getBaseUrl() + `Databases/UpdateCustomers`, ids);
}
public resetCredentials(ids:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `Databases/createCredentials`, ids);
}
public migrate(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `migration/migrate/${id}`, {});
}
public reactivateLicense(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firms/activatecurrentlicense/${id}`, {});
}
public deactivateLicense(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firms/deactivatecurrentlicense/${id}`, {});
}
public getDbDetails(id): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `Databases/dbdetails/${id}`);
}
public deleteFirm(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `Databases/delete/${id}`, null);
}
public deleteFirms(firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `Databases/deletemany`, firms);
}
public backup(id:string): Promise<JobDetailsViewModel> {
  return httpService.post(Resources.getBaseUrl() + `Databases/backup/${id}`, null);
}
public downloadBackup(id:string, nonAzureCompantible:boolean = false): Promise<any> {
 return httpService.downloadAndSave(Resources.getBaseUrl() + `backups/downlaod/${id}?nonAzureCompatible=${nonAzureCompantible}`);
}
public restore(id:string): Promise<JobDetailsViewModel> {
  return httpService.post(Resources.getBaseUrl() + `Databases/restore/${id}`, null);
}
public restoreDeleted(id:string): Promise<JobDetailsViewModel> {
  return httpService.post(Resources.getBaseUrl() + `Databases/restoredeletedfirm/${id}`, null);
}
public crossFirmRestore(firmId:string,backupId:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `Databases/crossfirmrestore/${firmId}/${backupId}`, null);
}
public deleteBackup(id:string): Promise<any> {
  return httpService.delete(Resources.getBaseUrl() + `backups/${id}`,null);
}
public updateBackup(backup: Backup): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `backups/${backup.id}`, backup);
}
public fixOldDueDates(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `DueDatesFix/setduedatestoallowextension/${id}`, {});
}
public fixWipMarkup(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firms/invoiceswithissues/${id}`, {});
}
public enablePermissioins(rights:string[],onlyIfHasRight:number,firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmrights/EnableRights`, {
    rights:rights,
    firms:firms,
    onlyIfHasRight:onlyIfHasRight
  });
}
public populateClientIdNr(firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `clients/populateclientidnr`, firms);
}
public convertClientsToSefContacts(firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmcontacts/ConvertClientsToSelfContact`, firms);
}
public executeBatchQuery(queryId:string,firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `BatchQuery/ExecuteQuery`, {
    queryId:queryId,
    firms:firms
  });
}
public runFindAndReplace(find:string,replace:string,firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `tasks/run/variablereplace`, {
    find:find,
    replace:replace,
    firms:firms
  });
}
public billingDetails(firmId:string): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `firms/billingdetails/${firmId}`);
}
public getBatchQueries(): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `BatchQuery/queries`);
}
public runQuery(firmId,query,useSecondary): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `BatchQuery/runquery`,{
    firmId:firmId,
    useSecondary:useSecondary,
    query:query
  });
}
public runManagerQuery(query): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `BatchQuery/ManagerQuery`,{
   
    query:query
  });
}
public runAdminQuery(query): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `BatchQuery/AdminQuery`,{
   
    query:query
  });
}
public runPortalQuery(query): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `BatchQuery/PortalQuery`,{
   
    query:query
  });
}
public getJobs(firmId:string, includeOutput:boolean=false): Promise<JobDetailsViewModel[]> {
  //8 tempate sync job type
  //1 & 2 job status queued, running
  // let queryString = '?types=8&';
  let   queryString = `firmId=${firmId}&includeOutput=${includeOutput||false}`
  return httpService.get(Resources.getBaseUrl() + `jobs?${queryString}`);
}

public isUrlAvailable(url:String): Promise<boolean>{
  return httpService.get(Resources.getBaseUrl() + `firms/urlexists?url=${url}`);

}
public changeUrl(url:String, firmId:String): Promise<void>{
  return httpService.post(Resources.getBaseUrl() + `firms/changeurl`,{
    newUrl:url,
    firmId:firmId
  });

}
}
export let dbService = new DbService();
