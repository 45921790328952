
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { powerBIService, jobTypes } from "../../utils/http/powerbi.service";
import { notificationService } from "../../utils/notification/notification.service";
import PowerBiJobs from "./PowerBIJobs.vue";
@Component({
  components: {
    PowerBiJobs,
  },
})
export default class extends componentBase {
  workspaces: any[] = [];
  powerBIJobTypes: any = jobTypes;
  fields = [     
    

    { key: "isSelected", sortable: false, label: "" },
    { key: "firmUrl", sortable: true, label: "Firm" },
    // { key: "license", sortable: true, label: "License" },
    { key: "subscription", sortable: true, label: "License" },
    { key: "hasErrors", sortable: true, label: "Errors" },
    { key: "powerBIReportCount", sortable: true, label: "Reports" },
    {
      key: "unconfiguredPowerBIReports",
      sortable: true,
      label: "Unconfigured Reports",
    },
    { key: "jobsInProgress", sortable: true, label: "Jobs" }
  ];

  workspaceFilter = {
    hasErrors: null,
    isSubscriptionActive: null,
    subscriptionName: '',
    filterWorkspaces(tenants) {
      return tenants.filter(tenant => {
        if (this.subscriptionName && tenant.license?.subscription != this.subscriptionName) {
          return false
        }

        if (this.hasErrors !== null && tenant.hasErrors != this.hasErrors) {
         return false
        }

        if (this.isSubscriptionActive !== null && tenant.license?.isActive != this.isSubscriptionActive) {
          return false;
        }

        return true;
      });
    }
  };
  get visibleWorkspaces(): any[] {

    return this.workspaceFilter.filterWorkspaces(this.workspaces)
  }
  created() {
    this.getData();
  }
  getData() {
    this.isBusy(true);
    powerBIService
      .getWorkspaces()
      .then((result) => {
        this.isBusy(false);
        console.log('result', result);
        
        this.workspaces = result.map(w => {
          const errors = [];
          const license = w.license || { isActive: false, subscription: 'Unknown' };
          const subscription = `${license.subscription}(${license.isActive ? 'Active' : 'Inactive'})`;

          if (w.powerBIReportCount === 0) {
            errors.push('No reports configured');
          }
          if (w.unconfiguredPowerBIReports > 0) {
            errors.push(`Has ${w.unconfiguredPowerBIReports} unconfigured reports`);
          }

          return {
            ...w,
            license,
            isSelected: false,
            subscription,
            errors,
            hasErrors: errors.length > 0
          };
        });
      })
      .catch((err) => {
        this.isBusy(false);
      console.log(err)
      });
  }
  batchSelect(selectFn: (record: any) => boolean) {
  this.workspaces.forEach(record => {
    record.isSelected =false
  });
  this.visibleWorkspaces.forEach(record => {
    record.isSelected = selectFn(record);
  });
}
  scheduleJob(firmId: string, jobType: Number) {
    powerBIService.schedulePowerBiJob([firmId], jobType).then((result) => {
      this.info(
        "Scheduled",
        "Update has been scheduled. This will take up to 40 minutes for a single firm. Depeneding on how many power bi jobs are in porgress this could take time to complete. Please do not schedule jobs for the same firm"
      );
    });
  }
  groupBy<T>(list: T[], keyGetter: (n: T) => any): Map<any, T[]> {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  get unconfiguredReportsStats(): any {
    var grouped = this.groupBy(this.workspaces, workspace => workspace.unconfiguredPowerBIReports);
    return Array.from(grouped.keys()).map(z => ({
      unconfigured: z,
      firms: grouped.get(z).length
    }));
  }
  get reportCountStats(): any {
    var grouped = this.groupBy(this.workspaces, workspace => workspace.powerBIReportCount);
    return Array.from(grouped.keys()).map(z => ({
      reports: z,
      firms: grouped.get(z).length
    }));
  }
  get subscriptionStats(): any {
    var grouped = this.groupBy(this.workspaces, workspace => workspace.subscription);
    return Array.from(grouped.keys()).map(z => ({
      name: z,
      count: grouped.get(z).length
    }));
  }
  get totals(): any {
    return {
      totalFirms: this.workspaces.length,
      withErrors: this.workspaces.filter(z => z.hasErrors).length
    }

  }
  
  async batchRunJob(jobType: Number) {
    var firmIds = this.visibleWorkspaces.filter(z=>z.isSelected).map(z => z.firmId);
    if(firmIds.length==0) return this.info('Cannot proceed','No entries are selected')
    var confirmation = await this.multiConfim("Confirm", [`Are you sure you want to perform action for ${firmIds.length} reports?`, 'Second confirmation', 'Final warning']);
    if (!confirmation) return;
    powerBIService.schedulePowerBiJob(firmIds, jobType).then(result => {
      this.info("Job scheduled", "Check the job details for firms to view the status. ");
    }).catch(err => this.info("Error", "Something went wrong during the scheduling of the job"))
  }
}

