

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      chartOptions: [],
    };
  },
  methods: {
    buildChatOptions() {
     let byRegionDBHits=this.data.regions.map(d=>{
       

    return {
      options: {
        labels: [ 'Primary','Secondary'],
        legend: {
          position: 'bottom',
        },
        chart: {
          type: 'pie',
          height:200
        },
        title: {
          text: `${d.region} -  ${d.total}`,
          align: 'center',
          style: {
            fontSize: '16px',
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      series:[d.primaryDbCount,d.secondaryDbCount]
    }
    
     })
     const regionTotals={
      options: {
        labels: this.data.regions.map(z=>z.region),
        legend: {
          position: 'bottom',
        },
        chart: {
          type: 'pie',
          height:200
        },
        title: {
          text: 'Total Runs:' +this.data.total,
          align: 'center',
          style: {
            fontSize: '16px',
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      series: this.data.regions.map(z=>z.total)
     }
     return [regionTotals,...byRegionDBHits]
    },
  },
  mounted() {
    // Calculate and set data when the component is mounted
    this.chartOptions=this.buildChatOptions();
  },
};
