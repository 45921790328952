
import { Component, Prop, Vue } from "vue-property-decorator";

import CustomerBase from '@/common/customer.base'
import reportDataUtils from '../ReportDataUtils'
@Component({
  components: {
    
  }
})
export default class ReportChartView extends CustomerBase {
  @Prop()
  data: any[];
  @Prop()
  range: any;
  fields= [
          { key: 'time',            label: 'Time(Local)', sortable: true },
          { key: 'tenantName', label: 'Firm', sortable: true },
          { key: 'reportName',label: 'Report',  sortable: true },
          { key: 'isSecondaryDb',label: 'Secondary',  sortable: true },
        ]
        totalRows= 1;
        currentPage= 1;
        perPage= 500;
        sortBy='time'
        sortDesc=true
  created() {
   this.totalRows=this.data.length
  }
  

 

}
