
import { Component, Prop, Vue } from "vue-property-decorator";
import { firmUSersService } from "../../utils/http";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";
import { debounce } from "@/utils";
import store from "@/store";

enum UserFirmRole {
    Administrator = 1,
    Employee,
    Client,
    InternalAdmin,
    FirmCoAdministrator,
    Companion
}

interface UserAccess {
    id: number;
    role: string;
    roleId: number;
    firmOwnerId: string | null;
    firmOwnerName: string | null;
}

interface DistinctRole {
    roleId: number;
    roleName: string;
}

interface GroupedUser {
    loginUserFlowName: string | null;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    access: UserAccess[];
    distinctRoles: DistinctRole[];
}

@Component({
    components: {
        ActionButton,
        FormError
    }
})
export default class Events extends CustomerBase {
    constructor() {
        super();
        this.handleSearchInput = debounce.debounce(this.handleSearchInput, 300);
    }
    @Prop()
    firmId!: string;
    isLoading: boolean  = false;

    users: GroupedUser[] = [];
    searchQuery = '';
    selectedRole: UserFirmRole | null = null;
    hasMFA: boolean | null = null;
    get hasMFAOptions() {
        return [
            { value: null, text: 'MFA: Any' },
            { value: true, text: 'MFA: Yes' },
            { value: false, text: 'MFA: No' }
        ];
    }
    get roleOptions() {
        return [
            { value: null, text: 'All Roles' },
            { value: UserFirmRole.Administrator, text: 'Administrator' },
            { value: UserFirmRole.Employee, text: 'Employee' },
            { value: UserFirmRole.Client, text: 'Client' },
            { value: UserFirmRole.InternalAdmin, text: 'Internal Admin' },
            { value: UserFirmRole.FirmCoAdministrator, text: 'Firm Co-Administrator' },
            { value: UserFirmRole.Companion, text: 'Companion' }
        ];
    }

    get filteredUsers() {
    return this.users.filter(user => {
        const matchesSearch = this.searchQuery
            ? (user.firstName + ' ' + user.lastName + ' ' + user.email)
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            : true;

        const matchesRole = this.selectedRole
            ? user.distinctRoles.some(role => role.roleId === this.selectedRole)
            : true;
            
        const matchesMFA = this.hasMFA === null 
            ? true 
            : this.hasMFA 
                ? !!user.loginUserFlowName 
                : !user.loginUserFlowName;

        return matchesSearch && matchesRole && matchesMFA;
    });
}

    handleSearchInput(value: string) {
        
        this.searchQuery = value;
    }

    getDistinctRoles(accesses: UserAccess[]): DistinctRole[] {
        const distinctRolesMap = new Map<number, string>();
        
        accesses.forEach(access => {
            if (!distinctRolesMap.has(access.roleId)) {
                distinctRolesMap.set(access.roleId, access.role);
            }
        });

        return Array.from(distinctRolesMap.entries()).map(([roleId, roleName]) => ({
            roleId,
            roleName
        }));
    }

    groupUsers(users: any[]): GroupedUser[] {
        const groupedMap = new Map<string, UserAccess[]>();
        
        // Group users by userId
        users.forEach(user => {
            if (!groupedMap.has(user.userId)) {
                groupedMap.set(user.userId, []);
            }
            groupedMap.get(user.userId)?.push({
                id: user.id,
                role: user.role,
                roleId: user.roleId,
                firmOwnerId: user.firmOwnerId,
                firmOwnerName: user.firmOwnerName
            });
        });

        // Convert grouped map to final array format
        const result: GroupedUser[] = [];
        groupedMap.forEach((accesses, userId) => {
            const firstRecord = users.find(u => u.userId === userId);
            if (firstRecord) {
                result.push({
                    userId,
                    loginUserFlowName: firstRecord.loginUserFlowName,
                    firstName: firstRecord.firstName,
                    lastName: firstRecord.lastName,
                    email: firstRecord.email,
                    access: accesses,
                    distinctRoles: this.getDistinctRoles(accesses)
                });
            }
        });

        return result;
    }

    async created() {
        await this.initialize();
    }

    async initialize() {
        try {
            this.isLoading=true;
            const rawUsers = await firmUSersService.getUsers(this.firmId, 1);
            this.users = this.groupUsers(rawUsers);
            this.isLoading=false;
        } catch (err) {
            this.isLoading=false;
        }
    }

    async removeUser(id: number) {
        let confirmation = await this.confirm(
            "Confirm!",
            "Are you sure you want to remove this access? The user won't be able to manage the firm anymore"
        );
        if (!confirmation) return;
        await firmUSersService.removeFirmUser(id);
        this.initialize();
    }

    async removeLoginFlowRestriction(id: string) {
        let confirmation = await this.confirm(
            "Confirm!",
            "Are you sure you want to remove login restriction from user? They will be set to use the default login flow but if the firm requires them to use multifactor they must set it up on their next login"
        );
        if (!confirmation) return;
        await firmUSersService.removeUserLoginFlow(id);
        this.initialize();
    }
}
